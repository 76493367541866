import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg-indigo-900 text-white flex justify-center" }
const _hoisted_2 = { class: "container pb-8 p-3" }
const _hoisted_3 = { class: "md:flex" }
const _hoisted_4 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.linksSetOne, _ctx.linksSetTwo], (links) => {
          return (_openBlock(), _createElementBlock("ul", {
            class: "pr-20",
            key: links
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links, (link) => {
              return (_openBlock(), _createElementBlock("li", {
                key: link.label(),
                class: "pt-2"
              }, [
                _createVNode(_component_router_link, {
                  to: link.to
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(link.label()), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            }), 128))
          ]))
        }), 128))
      ]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('navigation.footer_description')), 1)
    ])
  ]))
}